<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Talep Oluştur
          </h6></b-col>
        </b-row>
      </b-card-text>
      <b-form-group label="Departman">
        <v-select
          v-model="purchasingRequest.id_com_department"
          :options="departments"
          :reduce="department => department.id"
          label="title"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group label="Marka">
        <v-select
          v-model="purchasingRequest.id_com_brand"
          :options="brands"
          :reduce="brand => brand.id"
          label="name"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-row
        v-for="(item, index) in purchasingRequest.lines"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col md="4">
          <b-form-group
            label="Ürün Grubu"
          >
            <v-select
              v-model="item.id_com_product_groups"
              :options="productGroups"
              placeholder="Seçiniz"
              :reduce="productGroup => productGroup.id"
            >
              <template v-slot:option="option">
                {{ option.title }}
                <div>
                  <small class="text-danger">{{ option.product_type }}</small>
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.title }}&nbsp;
                <div class="text-danger">
                  <small> {{ option.product_type }}</small>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Açıklama"
          >
            <b-form-input
              v-model="item.content"
              placeholder="Açıklama"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Miktar"
          >
            <b-form-input
              v-model="item.quantity"
              placeholder="Miktar"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Ünite"
          >
            <v-select
              v-model="item.id_com_product_units"
              :options="productUnits"
              :reduce="productUnit => productUnit.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          md="3"
          class="mb-50"
        >
          <b-button
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Sil</span>
          </b-button>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mb-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Ekle</span>
      </b-button>
      <b-form-group
        label="Not"
      >
        <b-form-input
          v-model="purchasingRequest.notes"
          placeholder="Not"
        />
      </b-form-group>
      <b-form-group label="Görsel">
        <b-form-file
          v-model="purchasingRequest.image"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Görsel Seçin"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="purchasingRequest.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormFile,
  },
  mixins: [heightTransition],
  computed: {
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    productGroups() {
      return this.$store.getters['productGroups/getProductGroups']
    },
    productUnits() {
      return this.$store.getters['productUnits/getProductUnits']
    },
    purchasingRequest() {
      return this.$store.getters['purchasingRequests/getPurchasingRequest']
    },
    saveStatus() {
      return this.$store.getters['purchasingRequests/getPurchasingRequestSave']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.$store.dispatch('purchasingRequests/purchasingRequestReset')
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.purchasingRequest.submitStatus = false
    },
  },
  created() {
    this.getDepartments()
    this.getBrands()
    this.getProductGroups()
    this.getProductUnits()
  },
  methods: {
    getDepartments() {
      this.$store.dispatch('departments/departmentsList')
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList')
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/productGroups')
    },
    getProductUnits() {
      this.$store.dispatch('productUnits/productUnits')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.purchasingRequest.submitStatus = true
      this.$store.dispatch('purchasingRequests/purchasingRequestsSave', this.purchasingRequest)
    },
    repeateAgain() {
      this.purchasingRequest.lines.push({
        id_com_product_groups: null,
        id_com_product_units: null,
        content: null,
        quantity: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.purchasingRequest.lines.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>
